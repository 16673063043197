<template>
  <div class="settings-wrapper">
    <div class="settings">
      <h1>Project Settings</h1>
      <h2 class="settings__action-header">Details</h2>
      <div class="settings__edit-project">
        <h3>Project Name</h3>
        <input type="text" :class="[disableNameField ? '': 'edit']"
          class="settings__input-project"
          :disabled="disableNameField" v-model="projectName">
        <svg class="edit" v-if="disableNameField" @click="disableNameField = false">
          <use v-bind="{'xlink:href':'#edit'}" />
        </svg>
        <div class="button-wrapper" v-if="!disableNameField">
          <button class="btn-style-common outlined medium" @click="disableNameField = true">Cancel</button>
          <button class="btn-style-common medium" @click="editProject">Confirm</button>
        </div>
      </div>
      <div class="settings__license"
        :class="[activeProject.project_license.license_key.isPackage ? 'add-padding' : '']">
        <div class="settings__license-block" v-if="activeProject.project_license">
          <div>
            <h3 class="settings__license-header">Current Plan</h3>
            <a :href="link+ '/v5/package-pricing'" title="Pricing" class="settings__license-block-info">
              {{ activeProject.project_license.common_name }}
              <svg>
                <use v-bind="{ 'xlink:href': '#license-arrow' }" />
              </svg>
            </a>
          </div>
          <a
            :href="sportsLink+'pricing/'"
            class="btn-style-common outlined medium"
            v-if="!activeProject.project_license.license_key.isEnterprise"
          >All Plans</a>
        </div>
      </div>
      <PrioritySupport
        v-if="!activeProject.project_license.license_key.isPackage"
        class="priority-support"
      />
      <h2 class="settings__action-header">Actions</h2>
      <div class="settings__major-actions">
        <div class="settings__reset-api" :class="isResetKeyBoxExpanded ? 'expanded' : null">
          <div class="settings__reset-api--top-row">
            <img src="@/assets/reset.svg">
            <p>
              Reset your current API key and generate a new one.</p>
            <button
              class="btn-style-common medium outlined error"
              @click="isResetKeyBoxExpanded = !isResetKeyBoxExpanded"
            >Reset API Key</button>
          </div>
          <div class="settings__reset-api--bottom-row">
            <p class="border"></p>
            <p class="delete-question">Are you sure you want API Key
              <span class="api-key"
               v-if="activeProject.active_api_keys && activeProject.active_api_keys.length">
               {{ activeProject.active_api_keys[0].api_key }} </span> be resetted?</p>
            <div class="delete-action">
              <button
                class="btn-style-common medium outlined"
                @click="isResetKeyBoxExpanded = !isResetKeyBoxExpanded"
              >Cancel</button>
              <button
                class="btn-style-common medium error-btn"
                :disabled="!isResetKeyBoxExpanded"
                @click="resetApiKey"
              >Reset</button>
            </div>
          </div>
        </div>
        <div class="settings__delete-project" :class="isDeleteBoxExpanded ? 'expanded' : null">
          <div class="settings__delete-project--top-row">
            <img src="@/assets/delete.svg">
            <p>
              Delete your project permanently. This action cannot be undone.</p>
            <button
              class="btn-style-common medium outlined error"
              @click="isDeleteBoxExpanded = !isDeleteBoxExpanded"
            >Delete Project</button>
          </div>
          <div class="settings__delete-project--bottom-row">
            <p class="border"></p>
            <p class="delete-question">Are you sure you want to delete the project ?</p>
            <div class="delete-action">
              <button
                class="btn-style-common medium outlined"
                @click="isDeleteBoxExpanded = !isDeleteBoxExpanded"
              >Cancel</button>
              <button
                class="btn-style-common medium error-btn"
                :disabled="!isDeleteBoxExpanded"
                @click="deleteProject"
              >Continue</button>
            </div>
          </div>
        </div>
      </div>
      <div class="settings__owner-details"
        v-if="activeUser.groups && activeUser.groups.length > 0 && activeProject.created_by">
        <h2 class="settings__action-header">Owner Details</h2>
        <div class="details-wrapper">
          <p>
            <span class="title">Name</span>:
            <span class="project-name">{{activeProject.created_by.name}}</span>
          </p>
          <p>
            <span class="title">Email</span>:
            <span class="project-name"><a :href="'mailto:'+ activeProject.created_by.email">
              {{activeProject.created_by.email}}</a></span>
          </p>
          <p v-if="activeProject.created_by.properties.company_name">
            <span class="title">Company name</span>:
            <span>{{activeProject.created_by.properties.company_name}}</span>
          </p>
          <p v-if="activeProject.created_by.properties.stripe_customer_id">
            <span class="title">Stripe Customer id</span>:
            <span>{{activeProject.created_by.properties.stripe_customer_id}}</span>
          </p>
          <p v-if="activeProject.created_by.email_verified">
            <span class="title">Email verified</span>:
            <span>{{activeProject.created_by.email_verified ? 'Yes': 'No'}}</span>
          </p>
          <p v-if="activeProject.created_by.active">
            <span class="title">User Active</span>:
            <span>{{activeProject.created_by.active ? 'Yes': 'No'}}</span>
          </p>
          <p v-if="activeProject.created_by.created">
            <span class="title">User created on</span>:
            <span>{{ $filters.utcLocalDate(activeProject.created_by.created) }}</span>
          </p>
          <p v-if="activeProject.created_by.updated">
            <span class="title">User updated on</span>:
            <span>{{ $filters.utcLocalDate(activeProject.created_by.updated) }}</span>
          </p>
          <p v-if="activeProject.created_by.last_login">
            <span class="title">User last logged on</span>:
            <span>{{ $filters.utcLocalDate(activeProject.created_by.last_login) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.settings {
  .btn-style-common {
    &.error {
      margin-top: size(5);
    }
  }
  .priority-support {
    margin-bottom: size(30);
    .standard-wrapper, .enterprise-wrapper {
      padding: size(20) size(15);
      h4 {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      }
      p {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
      }
      .btn-style-common {
        padding: size(10) size(18);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.settings {
  max-width: size(610);
  padding-bottom: size(50);
  h1 {
    @include font-styles($type: 'display', $size: 'xs', $weight: 'bold');
    color: var(--global--main--title--Color);
    padding: size(30) 0;
  }
  h2 {
    @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
    color: var(--global--main--title--Color);
    margin-bottom: size(14);
  }
  &__edit-project {
    padding: size(15);
    margin-bottom: size(10);
    background: var(--global--white--Color);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    h3 {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      margin-bottom: size(6);
      color: var(--global--main--title--Color);
    }
    input {
      width: 97%;
    }
    .button-wrapper {
      margin-top: size(10);
      text-align: right;
      button {
        cursor: pointer;
        &:first-child {
          margin-right: size(10);
        }
      }
    }
  }
  &__edit-action {
    display: inline-block;
    text-align: right;
    width: size(145);
    svg {
      width: size(20);
      height: size(20);
      fill: var(--global--white--Color);
    }
  }
  &__reset-apikey {
    display: flex;
    justify-content: space-between;
    padding: size(20);
    background: var(--global--white--Color);
    margin-bottom: size(10);
    border-radius: size(8);
    p {
      margin: auto 0;
    }
    button {
      padding: size(0);
    }
  }

  &__delete-project, &__reset-api {
    display: flex;
    flex-direction: column;
    padding: size(15);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    background: var(--global--white--Color);
    margin-bottom: size(10);
    border-radius: size(8);
    max-height: size(78);
    overflow: hidden;
    transition: max-height 0.3s;
    &--top-row {
      margin-bottom: size(15);
      p {
        width: 70%;
        display: inline-block;
        vertical-align: top;
        padding-top: size(12);
        padding-right: size(25);
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
      }
      img {
        display: inline-block;
        vertical-align: top;
        width: size(24);
        height: size(24);
        margin-top: size(9);
        margin-right: size(10);
      }
      .btn-style-common {
        &.error {
          vertical-align: top;
          font-size: size(12);
          padding: size(10) size(18);
          border: size(1) solid var(--global--error--BackgroundColor);
          color: var(--global--error--BackgroundColor);
          cursor: pointer;
          float: right;
        }
      }
    }
    &--bottom-row {
      .border {
        padding-top: size(7);
        border-bottom: size(1) solid var(--global--line--BackgroundColor);
        margin-bottom: size(20);
      }
      .delete-question {
        text-align: center;
        margin-bottom: size(10);
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
        color: var(--global--main--title--Color);
        .api-key {
          font-weight: var(--global--primary--semibold--FontWeight);
        }
      }
      .delete-action {
        text-align: center;
        padding-bottom: size(10);
        button {
          &:not(:last-of-type) {
            margin-right: size(30);
          }
        }
      }
    }
    p {
      margin: auto 0;
    }
    .delete-action {
      .btn-style-common {
        cursor: pointer;
        &.error-btn {
          border: size(1) solid var(--global--error--BackgroundColor);
          background-color: var(--global--error--BackgroundColor);
        }
      }
    }
    &.expanded {
      max-height: size(220);
      overflow: hidden;
      transition: max-height 0.3s;
    }
  }
  &__delete-project {
    margin-bottom: size(30);
    &--top-row {
      p {
        padding-top: size(3);
      }
    }
  }
  &__project-header {
    margin-bottom: size(13);
  }
  &__action-header {
    margin-bottom: size(15);
  }
  &__input {
    &-project {
      margin-bottom: size(17);
    }
  }
  &__input-project {
    width: size(435);
    background: none;
    border: none;
    color: var(--global--main--title--Color);
    margin-bottom: 0;
    -webkit-text-fill-color: var(--global--main--title--Color);
    opacity: 1;
    @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
    &:focus {
      outline: none;
    }
    &:disabled {
      color: var(--global--main--title--Color);
    }
    &.readonly {
      cursor: not-allowed;
      background: none;
      border: none;
    }
    &.edit {
      padding: size(14);
      width: 100%;
      border: size(1) solid var(--global--gray--Color);
      border-radius: size(8);
    }
  }
  svg {
    &.edit {
      stroke: #777;
      fill: transparent;
      display: inline-block;
      vertical-align: middle;
      width: size(15);
      height: size(15);
      cursor: pointer;
    }
  }
  &__license {
    padding: size(15);
    width: 100%;
    border-radius: size(5);
    margin-bottom: size(10);
    background: var(--global--white--Color);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    &.add-padding {
      margin-bottom: size(30);
    }
    h3 {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      margin-bottom: size(6);
      color: var(--global--main--title--Color);
    }
    a {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      display: inline-block;
      color: var( --global--common-link--Color);
      svg {
        display: inline-block;
        fill: transparent;
        width: size(18);
        height: size(18);
        stroke: var( --global--common-link--Color);
        vertical-align: middle;
      }
    }
    .btn-style-common {
      padding: size(10) size(18);
      margin-top: size(6);
      height: size(38);
    }
    &-block {
      display: flex;
      justify-content: space-between;
      &-btn {
        padding: size(0);
      }
      &-info {
        color: var(--global--supportive--title--Color);
      }
    }
  }
  &__owner-details {
    h2 {
      margin-bottom: size(14);
    }
    .details-wrapper {
      padding: size(20);
      width: 100%;
      border-radius: size(5);
      margin-bottom: size(30);
      background: var(--global--white--Color);
      p {
        padding-bottom: size(10);
      }
      span {
        display: inline-block;
        vertical-align: top;
        padding-left: size(10);
        @include font-styles($type: 'label', $size: 'md', $weight: 'regular');
        &.title {
          @include font-styles($type: 'label', $size: 'lg', $weight: 'semibold');
          width: 40%;
          padding-left: 0;
        }
      }
    }
  }
}
</style>
<script>
import Config from '@/config';
import { mapState } from 'vuex';
import { Project } from '@/store/modules/project';
import PrioritySupport from '@/components/PrioritySupport.vue';

export default {
  name: 'Settings',
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeUser: (state) => state.user.activeUser,
    }),
  },
  beforeMount() {
    this.projectName = this.activeProject.name;
  },
  components: {
    PrioritySupport,
  },
  data() {
    return {
      isDeleteBoxExpanded: false,
      isResetKeyBoxExpanded: false,
      editMode: false,
      deleteResponse: false,
      editResponse: false,
      projectName: null,
      link: Config.cricketApiHost,
      sportsLink: Config.sportsHost,
      disableNameField: true,
    };
  },
  methods: {
    deleteProject() {
      this.isDeleteBoxExpanded = !this.isDeleteBoxExpanded;
      // eslint-disable-next-line arrow-body-style
      this.activeProject.delete().then(() => {
        this.$store.dispatch('project/fetchProjects').then((projects) => {
          if (projects && projects.length) {
            this.$store.commit('project/updateActiveProject', projects[0]);
          }
        }).catch((err) => {
          console.log('Error on fetchActiveProject', err);
        });
        this.$router.push({ name: 'dashboard' });
      }).catch((err) => {
        console.log('eddeleteit project', err);
      });
    },
    resetApiKey() {
      this.isResetKeyBoxExpanded = !this.isResetKeyBoxExpanded;
      const resetObj = {
        action: 'reset_api_key',
        api_key: this.activeProject.active_api_keys[0].api_key,
        create_new: true,
      };
      this.activeProject.postProjectActions(resetObj).then((resp) => {
        console.log('resp', resp);
        this.$store.dispatch('project/fetchProject', this.activeProject.key).catch((err) => {
          console.log('Reset error', err);
        });
      });
    },
    editProject() {
      this.editMode = !this.editMode;
      this.disableNameField = true;
      const postObj = {
        name: this.projectName,
      };
      Project.editProject(this.activeProject.key, postObj).then(() => {
        this.$store.commit('project/updateProjectName', this.projectName);
      }).catch((err) => {
        console.log('edit project', err);
      });
    },

    cancelEdit() {
      this.editMode = !this.editMode;
      this.projectName = this.activeProject.name;
    },

    enableEdit() {
      this.editMode = !this.editMode;
      this.$refs.editProjectField.focus();
    },
  },
};
</script>
